@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');

.material-icons,
.material-icons-outlined {
	display: block;
	font-size: 1.25em;
	font-weight: inherit;
}

:root {
	color-scheme: dark;
}

body {
	background-color: #19191a;
	color: #dedee3;
	font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	margin: 0;
	min-height: 100vh;
	width: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@supports (padding: max(0px)) {
	body {
		padding-bottom: env(safe-area-inset-bottom, 0vmin);
		padding-left: env(safe-area-inset-left, 0vmin);
		padding-right: env(safe-area-inset-right, 0vmin);
		padding-top: env(safe-area-inset-top, 0vmin);
	}
}

#stremmer-root {
	min-height: 100vh;
	position: relative;
	width: 100%;
	z-index: 1;
}
