/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/root';

$theme-colors: map-remove($theme-colors, 'light', 'dark');

// $theme-colors: map-merge($theme-colors, $colours-brands);
$theme-colors: map-merge($theme-colors, $brands);

@import 'bootstrap/scss/utilities';

// Layout & components
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
// @import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
// @import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
// @import 'bootstrap/scss/transitions';
// @import 'bootstrap/scss/dropdown';
// @import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
// @import 'bootstrap/scss/accordion';
// @import 'bootstrap/scss/breadcrumb';
// @import 'bootstrap/scss/pagination';
// @import 'bootstrap/scss/badge';
// @import 'bootstrap/scss/alert';
// @import 'bootstrap/scss/progress';
// @import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
// @import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
// @import 'bootstrap/scss/tooltip';
// @import 'bootstrap/scss/popover';
// @import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/spinners';
// @import 'bootstrap/scss/offcanvas';
// @import 'bootstrap/scss/placeholders';

// Helpers
// @import 'bootstrap/scss/helpers';

// Utilities - why the fuck are TEXT alignment classes in something called API?
// Cunting bootstrap bullshit...
// @import 'bootstrap/scss/utilities/api';
// scss-docs-end import-stack
