@import 'scss/variables';

.range-input {
	align-items: center;
	display: flex;
	column-gap: 0.5rem;
	width: 100%;

	input[type='range'] {
		margin: 0 !important;
		width: 100%;
	}
	input[type='number'] {
		max-width: 4rem;
	}
}

textarea {
	resize: none !important;
}
