@use 'sass:math';

@import 'scss/variables';
@import 'scss/mixins';

// @import '@sweetalert2/theme-dark/dark.scss';
@each $brand, $colour in $colours-brands {
	.brand-#{$brand} {
		color: #{$colour};
		fill: $colour;
	}
	svg.brand-#{$brand} {
		fill: $colour;
	}
}

a.active,
summary,
.stremmer-text {
	color: $white !important;
	font-weight: 400;
	text-decoration: none;
	&:after {
		color: $magenta;
		content: '.';
	}
	&:hover {
		color: inherit;
		text-decoration: none;
	}
}

@import 'scss/custom-bs';

:root {
	--toastify-color-light: $colour-text;
	--toastify-color-dark: $colour-background;
	--toastify-color-info: #3498db;
	--toastify-color-success: $green;
	--toastify-color-warning: $yellow;
	--toastify-color-error: #e74c3c;
}

* {
	user-select: none;
	-webkit-user-drag: none;
}
img,
button,
.btn * {
	user-select: none;
}

img {
	max-width: 100%;
	width: 100%;
}

header,
nav,
footer * {
	* {
		user-select: none;
	}
}

.btn {
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 0.25rem;
	text-transform: capitalize;
	white-space: nowrap;
}

.stremmer-container {
	display: grid;
	gap: 1rem;

	// grid-template:
	// 	'header' minmax(3rem, max-content)
	// 	'nav' auto
	// 	'main' auto
	// 	'footer' minmax(max-content, 3rem);
	// // grid-template-columns: min-content auto;
	// // grid-template-columns: minmax(auto, 10%) auto;
	// grid-template-rows: max-content min-content auto auto;
	grid-template:
		'header header' minmax(3rem, max-content)
		'nav nav' minmax(3rem, min-content)
		'main main' auto
		'footer footer' minmax(max-content, 3rem);
	grid-template-columns: auto;
	// grid-template-columns: minmax(auto, 10%) auto;
	// grid-template-rows: max-content auto auto;
	grid-template-rows: max-content max-content auto minmax(min-content, 2.5rem);

	@media screen and (min-width: 1200px) {
		grid-template:
			'header header' minmax(3rem, max-content)
			'nav main' auto
			'footer footer' minmax(max-content, 3rem);
		// grid-template-columns: min-content auto;
		grid-template-columns: minmax(auto, 10rem) auto;
		// grid-template-rows: max-content auto auto;
		grid-template-rows: max-content auto minmax(min-content, 2.5rem);
	}
	margin: 0 auto;
	max-width: 1140px;
	min-height: 100vh;
	padding: 0 0.75rem;
	width: 100%;

	> header {
		grid-area: header;
	}
	> nav {
		grid-area: nav;
	}
	> main {
		grid-area: main;
		&.stremmer-homepages {
			grid-area: nav / span 2;
		}
		.content {
			display: grid;
			gap: 1rem;
			grid-auto-rows: max-content;
			height: 100%;
		}
	}
	> footer {
		align-items: center;
		display: flex;
		grid-area: footer;
	}
}

// .stremmer-grid {
// 	display: grid;
// 	gap: 1rem;
// 	@for $i from 1 through 6 {
// 		&.grid-#{$i} {
// 			// @include header($i);
// 			grid-template-columns: repeat($i, 1fr);
// 		}
// 	}
// }
@for $i from 1 through 6 {
	.stremmer-grid-#{$i} {
		display: grid;
		gap: 1rem;
		height: fit-content;
		// grid-template-columns: repeat($i, 1fr);
		grid-auto-rows: max-content;

		@media (min-width: 576px) {
			grid-template-columns: 1fr;
		}
		@media (min-width: 768px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (min-width: 992px) {
			grid-template-columns: repeat($i, 1fr);
		}
	}
	.stremmer-grid-span-#{$i} {
		grid-column: span $i;
	}
}

.account-card {
	.card-header {
		align-items: center;
		column-gap: 0.5rem;
		display: flex;
		justify-content: space-between;
	}
	.card-header-new {
		align-items: center;
		column-gap: 0.5rem;
		display: grid;
		grid-template-areas: 'icon name . .';
		grid-template-columns: [icon] 1rem [name] 1fr auto auto;
		// justify-content: space-between;
	}
	.card-body {
		align-items: center;
		display: grid;
		gap: 1rem;
		grid-template-columns: 4rem auto;
	}
}

.account-card-grid {
	align-items: baseline;
	display: grid;
	gap: 1rem;
	grid-template-columns: 4rem auto;
}

fieldset:disabled {
	button,
	input,
	select,
	textarea {
		cursor: not-allowed !important;
	}
}

.settings-footer {
	justify-content: space-between;
	display: flex;
}

.copy-clipboard {
	cursor: copy;
}

menu,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: block;
	li {
		display: block;
	}
	&.menu-list {
		justify-content: center;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 0.5rem;
		width: 100%;
		li {
			a {
				display: block;
				transition: color 0.15s linear;
			}
		}
		.menu-list-item {
			display: flex;
			flex-direction: row;
			gap: 0.5rem;
			summary {
				margin-bottom: 0.5rem;
			}
			details {
				display: flex;
				flex-direction: row;
				gap: 0.5rem;
				width: 100%;
			}
		}
		@media (min-width: 1200px) {
			flex-direction: column;
		}
	}
}
// details {
// 	summary {
// 		list-style: none;
// 	}
// 	> ul {
// 		margin-left: 1rem;
// 	}
// }

.breadcrumbs {
	display: flex;
	flex-wrap: nowrap;
	gap: 0.5rem;
	justify-content: flex-start;
	padding-bottom: 0.5rem;
	position: relative;
	> li {
		position: relative;
		&:after {
			content: '/';
			font-size: 0.8rem;
			position: relative;
			right: -0.25rem;
			user-select: none;
		}
		&:last-child {
			a {
				color: $white;
				pointer-events: none;
			}
			&:after {
				content: '';
			}
		}
	}
}

.card {
	height: 100%;
}

form {
	fieldset {
		display: grid;
		gap: 0.5rem;
	}
	div {
		.form-check-label,
		.form-label {
			font-size: 0.8rem;
			margin-bottom: 0.25rem;
		}
		.form-text {
			font-size: 0.75rem;
			text-align: right;
		}
	}
}

.btn-connect {
	align-items: center;
	display: grid;
	gap: 0.25rem;
	grid-template-columns: 1rem auto;
	margin: 0 auto;
	max-width: 8rem;
}

// .account-overview {
// 	height: 100%;
// 	.account-links {
// 		display: flex;
// 		flex-direction: column;
// 		height: 100%;
// 		// font-size: 0.8rem;
// 	}
// }

// .fa-regular,
// .fa-solid {
// 	font-size: 0.8em;
// 	padding-right: 0.25em;
// }
.fa-brands {
	&.fa-nightbot {
		font-family: 'Font Awesome 6 Free';
		font-weight: 900;
		&:before {
			content: '\f544';
		}
	}
	&.fa-twitter2 {
		font-family: 'Font Awesome 6 Brands';
		&:before {
			content: '\f099';
		}
	}
}

p {
	margin-bottom: 0;
}

// $table-gap: 1rem;

table {
	width: 100%;
	--table-row-gap: 1rem;
	tbody {
		display: grid;
		gap: var(--table-row-gap, 0);
		grid-auto-columns: 1fr;
		tr {
			position: relative;
			z-index: 1;
			&:after {
				border-bottom: 1px solid rgba($white, 0.125);
				bottom: calc(var(--table-row-gap) / -2);
				content: '';
				height: 0px;
				left: 0;
				position: absolute;
				width: 100%;
				z-index: 0;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}
