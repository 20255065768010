html,
body {
	background-color: transparent !important;
	margin: 0;
	padding: 0;
}

.overlay-layer {
	position: absolute;
	* {
		user-select: none;
	}
	.overlay-module {
		display: block;
		height: 100%;
		// overflow: hidden;
		position: relative;
		width: 100%;
		.overlay-module-content {
			display: block;
			// height: 100%;
			overflow: hidden;
			position: relative;
			width: 100%;
		}
	}
}
