#stremmer-header {
	align-content: center;
	align-items: center;
	align-self: center;
	display: flex;
	justify-content: space-between;
	padding: 0.5rem 0;

	.logo {
		font-size: 1.4rem;
	}

	.header-item {
		display: flex;
		justify-content: flex-end;
		.stremmer-header {
			align-content: center;
			align-items: center;
			display: flex;
			gap: 0.5rem;
		}
	}
}
