@import 'scss/variables';

.acr-attribution {
	align-items: center;
	column-gap: 0.5rem;
	display: flex;
	font-size: 0.8rem;
	font-weight: bold;
	justify-content: center;
	text-align: center;
	user-select: none;
	a {
		color: inherit;
		font-size: inherit;
	}
	img {
		display: block;
		height: 1.2rem;
	}
}

.musicid-results {
	// width: 100%;
	// tbody {
	// 	display: grid;
	// 	gap: 1rem;
	// }
	.musicid-result {
		align-items: center;
		display: grid;
		gap: 0.5rem;
		grid-template-areas:
			'date score'
			'title title'
			'artist artist';
		grid-template-columns: [date] auto [score] min-content;
		position: relative;
		width: 100%;
		@media (min-width: 992px) {
			grid-template-areas: 'date title artist score';
			grid-template-columns: [date] 7.5rem [title] 1fr [artist] 1fr [score] 2.5rem;
		}
		* {
			user-select: none;
		}
		.result-time {
			grid-area: date;
			font-size: 0.8rem;
		}
		.result-title {
			grid-area: title;
			font-weight: bold;
		}
		.result-artist {
			grid-area: artist;
		}
		.result-score {
			grid-area: score;
			font-size: 0.8rem;
			text-align: right;
		}
		.result-other {
			font-size: 0.8rem;
			text-align: center;
		}
	}
	.musicid-result-other {
		display: none;
		list-style: none;
		margin: 0;
		padding: 0;
	}
}
