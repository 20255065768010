@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

$colour-text: #dedee3;
$colour-background: #19191a;

$magenta: #f54897;
$pink: $magenta;
$blue: #5048f5;
$yellow: #eef548;
$green: #48f5a6;
$cyan: #48f5a6;

// $black: #121212;
$black: #000000;
$white: #dedee3;
// $yellow: #fdd032;
// $cyan: #00bab3;

$brands: (
	amazon: #ff9900,
	discord: #5865f2,
	github: #bd2c00,
	instagram: #c13584,
	mixcloud: #52aad8,
	nightbot: #f54897,
	paypal: #003087,
	spotify: #1db954,
	stremmer: #f54897,
	trovo: #19d66b,
	twitch: #9146ff,
	twitter: #1da1f2,
	youtube: #ff0000,
);

$colours-brands: (
	discord: #5865f2,
	nightbot: #f54897,
	spotify: #1db954,
	stremmer: #f54897,
	twitch: #9146ff,
	twitter: #1da1f2,
	youtube: #ff0000,
);

$min-contrast-ratio: 2;
$variable-prefix: stfu-;

$body-bg: $colour-background;
$body-color: $white !default;

$input-border-color: rgba($white, 0.5);
// $input-border-color: $white;

$border-width: 1px;

$btn-close-color: $magenta;

$primary: $magenta;
$primary-color: $black;

$input-btn-padding-y: 0.25rem;
$input-btn-padding-x: 0.5rem;
$input-btn-font-size: 1rem * 0.875;
$input-btn-line-height: 1.25;

$input-disabled-bg: $black;
$input-disabled-border-color: rgba($white, 0.5);

$form-input-disabled-color: rgba($white, 0.5);
$form-select-disabled-color: rgba($white, 0.5);
$form-range-track-height: 0.25rem;

// $headings-color: $magenta;

$card-bg: $black;
// $card-border-color: rgba($white, 0.25);
$card-border-color: rgba($magenta, 0.25);
$card-border-width: 2px;
$card-color: $white;

$link-color: $magenta;
$link-decoration: none;
$link-shade-percentage: 0%;
$link-hover-decoration: underline;

$modal-backdrop-bg: $colour-background;
$modal-backdrop-opacity: 0.5;
$modal-content-color: $white;
$modal-content-bg: $black;
$modal-content-border-color: rgba($white, 0.1);

$font-family-sans-serif: Poppins, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
	'Segoe UI Symbol', 'Noto Color Emoji';
