@import 'scss/variables';
@import 'scss/mixins';

#background {
	aspect-ratio: 16/9;
	background-color: $colour-background;
	// filter: blur(0.6px);
	height: auto;
	// left: 0;
	min-height: 100vh;
	min-width: 100vw;
	opacity: 1;
	overflow: hidden;
	pointer-events: none;
	position: fixed;
	// top: 0;
	left: 50%;
	top: 50%;
	transform: translate3d(-50%, -50%, 0);
	// transition-duration: 0ms;
	// transition-property: z-index;
	// transition-timing-function: linear;
	width: auto;
	z-index: -1;

	.background-content {
		align-content: center;
		display: grid;
		height: 100%;
		justify-content: center;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.spinner {
		align-items: center;
		align-content: center;
		display: none;
		flex-direction: column;
		gap: 0.5rem;
		justify-content: center;
		position: relative;
		text-align: center;
		z-index: 1;
	}

	&.loader {
		pointer-events: all;
		transition-delay: 250ms;
		z-index: 10;
		.spinner {
			display: flex;
		}
	}

	// &.loader {
	// 	pointer-events: all;
	// 	z-index: 10;
	// 	.spinner {
	// 		display: block;
	// 	}
	// }
}

canvas#ambient {
	aspect-ratio: 16/9;
	// filter: blur(0.6px);
	min-height: 100vh;
	min-width: 100vw;
	opacity: 0.75;
	overflow: hidden;
	z-index: 0;
}
