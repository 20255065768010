@import 'scss/variables';

@keyframes border-pulse {
	0% {
		border-color: rgba(red, 1);
	}
	100% {
		border-color: rgba(red, 0.5);
	}
}

.stremmer-avatar {
	aspect-ratio: 1 / 1;
	background-color: #000000;
	border: 2px solid $white;
	border-radius: 50%;
	display: block;
	min-width: 38px;
	max-width: 100px;
	object-fit: cover;
	overflow: hidden;
	padding: 2px;
	position: relative;
	width: 100%;

	.stremmer-header & {
		max-width: 38px;
	}

	&.live {
		animation: border-pulse 2s linear alternate infinite both;
		border-color: red;
	}

	img {
		aspect-ratio: 1 / 1;
		border-radius: 50%;
		display: block;
		height: auto;
		overflow: hidden;
		width: 100%;
	}
}

.stremmer-profile {
	column-gap: 1rem;
	display: grid;
	row-gap: 0.5rem;
	width: 100%;
	grid-template-areas: 'avatar profile' 'links profile' 'video video';
	grid-template-columns: 5rem auto;
	grid-template-rows: max-content 2rem auto;

	@media (min-width: 992px) {
		grid-template-areas: 'avatar profile video' 'links profile video';
		grid-template-columns: max-content auto 50%;
		grid-template-rows: auto 2rem;
	}
	* {
		user-select: none;
	}
	.profile-image {
		grid-area: avatar;
		max-width: 150px;
		width: 100%;
	}
	.profile-description {
		grid-area: profile;
	}
	.profile-links {
		align-self: center;
		grid-area: links;
		menu,
		ul {
			align-items: center;
			display: flex;
			flex-direction: row;
			gap: 0.5rem;
			justify-content: center;
		}
	}
	.profile-video {
		grid-area: video;
		aspect-ratio: 16/9;
		grid-area: video;
		height: auto;
		max-width: 40rem;
		width: 100%;
	}

	h4 {
		align-content: center;
		svg {
			display: inline-block;
			height: 1.5rem;
			margin-left: 0.5rem;
			width: 1.5rem;
		}
	}
}

.profile-pic {
	background-color: #000000;
	border: 2px solid $magenta;
	border-radius: 50%;
	display: inline-block;
	min-width: 30px;
	min-height: 30px;
	max-width: 30px;
}

.pagination {
	align-items: center;
	display: flex;
	gap: 0.5rem;
	justify-content: center;
	margin-top: 1rem;
	li {
		align-items: center;
		display: flex;
		justify-content: center;
		// padding: 0 0.5rem;
		&:not(.next, .previous) {
			// aspect-ratio: 1/1;
			// height: auto;
			width: 2rem;
		}
		&.disabled {
			> a {
				cursor: not-allowed;
				opacity: 0.5;
				text-decoration: none !important;
			}
		}
		&.selected {
			// border-color: rgba($magenta, 1);
			font-weight: bold;
			text-decoration: underline;
			text-decoration-color: #{$magenta};
			text-underline-offset: 0.25rem;
		}
	}
}
