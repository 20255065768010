@import 'scss/variables';

.condition-list {
	display: grid;
	gap: 1.5rem;

	input,
	select {
		width: 100%;
	}
}
.condition-add {
	color: $magenta;
	cursor: pointer;
	font-size: 0.8rem;
	font-weight: bold;
	text-align: center;
}

.condition-builder {
	display: grid;
	gap: 1rem;
}

.condition-groups {
	display: grid;
	gap: 2rem;
	input {
		width: 100%;
	}
	.condition-groups-item {
		align-items: center;
		border: 1px solid $magenta;
		border-radius: 0.25rem;
		display: grid;
		gap: 1rem;
		padding: 1rem;
		position: relative;
		&:not(:last-of-type) {
			&:after {
				bottom: -1.6rem;
				color: $magenta;
				content: '- OR -';
				font-size: 0.8rem;
				font-weight: bold;
				left: 50%;
				position: absolute;
				transform: translateX(-50%);
			}
		}
		.condition-rules {
			display: grid;
			gap: 2rem;
			.condition-rules-item {
				position: relative;
				&:not(:last-of-type) {
					&:after {
						bottom: -1.6rem;
						color: $magenta;
						content: '- AND -';
						font-size: 0.8rem;
						font-weight: bold;
						left: 50%;
						position: absolute;
						transform: translateX(-50%);
					}
				}
			}
			.condition-rule {
				align-items: center;
				display: grid;
				gap: 0.5rem;
				grid-template-columns: 1rem repeat(3, 1fr) 2.5rem;
				position: relative;
				// :not(:last-of-type) {
				// 	&:after {
				// 		bottom: -1.3rem;
				// 		color: $magenta;
				// 		content: '- AND -';
				// 		font-size: 0.8rem;
				// 		left: 50%;
				// 		position: absolute;
				// 		transform: translateX(-50%);
				// 	}
				// }
				input,
				select {
					width: 100%;
				}
			}
		}
	}
}
