@import 'scss/variables';
@import 'scss/mixins';

#stremmer-content {
	// 	display: grid;
	min-height: 100vh;
	min-height: -webkit-fill-available;
	position: relative;
	z-index: 1;
	// 	header, main, footer {
	// 		.container {
	// 			margin: 0 auto;
	// 			max-width: min(1200px,90vw);
	// 			padding: 0.5rem 0;
	// 		}
	// 	}
	// 	header {
	// 		nav {
	// 			color: #{map-get($colours, 'white')};
	// 			font-weight: #{map-get($font-weight, 'bold')};
	// 			display: flex;
	// 			flex-direction: row;
	// 			justify-content: space-between;
	// 			a {
	// 				color: inherit;
	// 				text-decoration: none;
	// 			}
	// 		}
	// 	}
}

#stremmer-footer {
	font-size: 0.8rem;
	menu,
	ul {
		display: flex;
		column-gap: 1rem;
		list-style: none;
		margin: 0;
		padding: 0;
	}
}
